@use "../configs/variables";

// Header
.submenu_container {
  position: absolute;
  left: -10000px;
  top: 0;
  z-index: 1000;
  width: 220px;
  max-height: 0;
  overflow-y: hidden;
  background-color: variables.$background-color;
  opacity: 0.3;
  transition:
    background-color 0.3s ease-out,
    opacity 0.3s ease-in-out,
    max-height 0.3s ease-out;

  &.show {
    max-height: 1000px;
    overflow-y: auto;
    opacity: 1;
  }

  &.work {
    background-color: white;
  }

  .submenu {
    padding: 0 0 10px 0;

    ul {
      li {
        display: block;
        margin-left: 0;

        div {
          display: inline-block;
          outline: none;
        }

        a {
          display: inline-block;
          padding: 3px 15px;
        }
      }
    }
  }

  .close {
    position: fixed;
    top: 0;
    right: -100%;
    display: none;
    width: 30px;
    height: 30px;
    overflow: hidden;
    text-indent: -9999px;
    background: url(../../assets/button-close-submenu.svg) no-repeat center
      center / 30px auto;
    opacity: 0.5;

    &.show {
      top: 11px;
      right: 11px;
      display: block;
    }

    &.on {
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: variables.$break-point) {
  .submenu_container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    &.show {
      max-height: 100vh;
    }

    .submenu {
      padding: 10px 0;

      ul {
        li {
          font-size: 16px;
          line-height: 2;

          a {
            padding: 0 20px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
