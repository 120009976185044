@use "../configs/variables";

// Untitled - 2009
article.work {
  max-width: 722px;

  .image_wrapper {
    border: 1px solid variables.$white-image-border-color;
    padding-bottom: 139.9%;
    width: 100%;
  }

  .info {
    margin-top: 35px;

    ul {
      list-style: none;
      margin-top: 20px;
    }

    ul + p {
      margin-top: 25px;
    }

    p {
      margin-top: 1em;
    }
  }
}

@media screen and (max-width: variables.$break-point) {
  article.work {
    margin: 0 0 30px 0;

    .image_wrapper {
      border-left: none;
      border-right: none;
    }

    .info {
      margin: 20px 20px 0 20px;
      padding: 0;
    }
  }
}
