@use "../configs/variables";

// Prelude
article.work {
  display: grid;
  grid-template-columns: calc(70% - 10px) 30%;
  gap: 10px;
  max-width: 1500px;

  .image_1,
  .image_2,
  .image_3 {
    background-color: rgba(variables.$background-color, 0.5);
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .image_1 {
    grid-column: 1 / 3;
    height: calc((100vw - 100px) * 0.54);
    max-height: 810px;
  }

  .image_2,
  .image_3 {
    height: calc((100vw - 100px) * 0.536);
    max-height: 818.47px;
  }

  .info {
    padding-top: 25px;

    ul {
      list-style: none;
      margin-top: 20px;
    }

    ul + p {
      margin-top: 25px;
    }

    p {
      margin-top: 1em;
    }
  }
}

@media screen and (max-width: 999px) {
  article.work {
    .info {
      grid-column: 1 / 3;
    }
  }
}

@media screen and (max-width: variables.$break-point) {
  article.work {
    margin: 0 0 30px 0;
    grid-template-columns: calc(70% - 5px) 30%;
    gap: 5px;

    .image_1 {
      height: calc(100vw * 0.54);
    }

    .image_2,
    .image_3 {
      height: calc(100vw * 0.536);
    }

    .info {
      margin: 20px 20px 0 20px;
      padding: 0;
    }
  }
}
