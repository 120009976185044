@use "configs/variables";

// App
.App {
  display: flex;
  flex-flow: column;
  height: 100%;

  article {
    flex: 1;
    margin: 0 50px 50px;

    &.not-found {
      margin-top: 25px;
    }
  }
}

@media screen and (max-width: variables.$break-point) {
  .App {
    article {
      margin: 10px 20px 30px 20px;

      &.not-found {
        margin: 30px 20px;
      }
    }
  }
}
