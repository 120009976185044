@use "../configs/variables";

.error {
  color: red;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.content {
  font-size: 84px;
  font-weight: 100;
  line-height: 0.9;
  letter-spacing: -0.05em;
  user-select: none;
  height: 100%;
  overflow: hidden;
}

.break {
  word-break: break-all;
}

.loader_container {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 10px;
  top: 10px;

  &.hide {
    display: none;
  }

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;

    border-top: 3px solid rgba(255, 255, 255, 0.4);
    border-right: 3px solid rgba(255, 255, 255, 0.4);
    border-bottom: 3px solid rgba(255, 255, 255, 0.4);
    border-left: 3px solid rgba(variables.$signature-color, 0.25);

    border-radius: 50%;
    text-indent: -9999px;
    animation: load8 1.2s infinite linear;
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .content {
    font-size: 54px;
  }
}
