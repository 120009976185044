@use "../configs/variables";

// Footer
footer {
  padding: 50px 50px 45px;
  font-size: 14px;

  .copyright {
  }

  .link {
    margin-top: 30px;

    ul {
      list-style: none;

      li {
        display: block;

        a {
          display: inline-block;
          line-height: 2;
        }
      }
    }
  }
}

@media screen and (max-width: variables.$break-point) {
  footer {
    padding: 20px;

    .copyright {
      font-size: 12px;
    }

    .link {
      margin-top: 10px;

      ul {
        li {
          font-size: 12px;

          a {
            line-height: 1.75;
          }
        }
      }
    }
  }
}
