@use "../configs/variables";

// Custom Cookie Consent
.CookieConsent {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: rgba(variables.$background-color, 0.9);
  border-top: 1px solid variables.$border-light-color;

  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9999;

  transition:
    background-color 0.3s ease-out,
    bottom 0.3s ease-out;

  &.work {
    background-color: rgba(white, 0.9);

    div {
      #rcc-confirm-button {
        &:hover {
          background-color: lighten(variables.$background-color, 2%);
        }
      }
    }
  }

  &.accepted {
    bottom: -40px !important;
  }

  div {
    font-size: 14px;

    &:first-child {
      margin: 0 15px;
    }

    #rcc-confirm-button {
      -webkit-tap-highlight-color: rgb(0, 0, 0, 0);
      border-left: 1px solid variables.$border-light-color;
      color: variables.$signature-color;
      padding: 10px 15px;
      transition: background-color 0.3s ease-out;

      &:hover {
        background-color: rgba(darken(variables.$background-color, 5%), 0.9);
      }
    }
  }
}

@media screen and (max-width: variables.$break-point) {
  .CookieConsent {
    &.accepted {
      bottom: -55px !important;
    }

    div {
      font-size: 12px;

      &:first-child {
        margin: 10px 0 10px 15px;
      }

      #rcc-confirm-button {
        border: none;
        font-size: 12px;
        width: 80px;

        &:hover {
          background-color: transparent;
          text-decoration: underline;
          text-decoration-color: variables.$border-hovered-color;
        }
      }
    }
  }
}
