// Variables

$scrollbar-width: 10px;

// Breakpoint
$break-point: 599px;

// Variables
$font-family: "Inter", "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "メイリオ",
  sans-serif;

$background-color: #e8eaed;
$signature-color: #444;
$error-color: #ff3686;

// Border
$border-hovered-color: lighten($signature-color, 35%);
$border-light-color: rgba($border-hovered-color, 0.25);

$white-image-border-color: #e6e6e6;
