@use "../configs/variables";

// Info / Contact
article.info {
  h1 {
    display: none;
  }

  h2 {
    font-size: 22px;
    letter-spacing: -0.01em;

    & + dl {
      margin-top: 12px;
    }
  }

  p,
  dl {
    font-size: 14px;
    line-height: 1.6;
  }

  .exhibitions,
  .performances,
  .contact {
    margin-top: 35px;

    dl {
      font-size: 14px;

      div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 7px;

        dt {
          width: 3.5em;
        }

        dd {
          width: calc(100% - 3.5em);
        }
      }
    }
  }

  // Contact
  .contact {
    margin-top: 50px;

    form {
      dl {
        div {
          display: block;
          margin-top: 15px;

          dt {
            width: auto;
          }

          dd {
            width: 100%;
          }
        }
      }

      // Form
      ::placeholder {
        color: variables.$signature-color;
      }

      label {
        display: none;
      }

      input,
      textarea {
        appearance: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        border: none;
        border-radius: 0;

        background-color: transparent;
        color: variables.$signature-color;
        font-family: variables.$font-family;
        font-size: 14px;
        line-height: 1.6;

        padding: 0;
        vertical-align: bottom;

        &:focus {
          outline: none;
        }
      }

      input:not([type="submit"]),
      textarea {
        border-bottom: 1px solid rgba(variables.$signature-color, 0.3);
        color: variables.$signature-color;
        width: 100%;
        max-width: 700px;
        transition:
          color 0.3s,
          border-bottom-color 0.3s;

        &:focus {
          border-bottom-color: rgba(variables.$signature-color, 0.85);
        }

        &[disabled] {
          // color: rgba(variables.$signature-color, 0.6);
        }
      }

      textarea {
        resize: none;
        overflow: hidden;
        height: auto;
        min-height: 23.4px;
      }

      .submit {
        cursor: pointer;

        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        text-decoration-color: transparent;
        text-underline-offset: 2px;

        transition:
          color 0.3s,
          text-decoration-color 0.3s ease-out;

        &[disabled] {
          cursor: default;
          color: rgba(variables.$signature-color, 0.35);
          text-decoration-color: transparent !important;
        }

        &.on {
          text-decoration-color: rgba(variables.$signature-color, 0.6);
        }
      }

      .error {
        color: variables.$error-color;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: variables.$break-point) {
  article.info {
    h2 + dl {
      margin-top: 10px;
    }

    .exhibitions,
    .performances,
    .contact {
      margin-top: 25px;
    }

    form {
      input,
      textarea {
        font-size: 1rem !important;
        font-weight: normal;
      }
    }
  }
}
