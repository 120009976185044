@use "configs/variables";
@import url("//fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
@import url("//fonts.googleapis.com/earlyaccess/notosansjapanese.css");

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  min-height: 100%;
}

body {
  background-color: variables.$background-color;
  color: variables.$signature-color;

  font-family: variables.$font-family;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
  transition: background-color 0s ease-out;

  &.loaded {
    transition-duration: 0.4s;
  }

  &.work {
    background-color: white;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// For 0bjekt.co
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1,
h2 {
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -0.015em;
}

strong {
  font-weight: 700;
}

ul {
  li {
    font-size: 14px;
    line-height: 1.6;
  }
}

p {
  font-size: 14px;
  line-height: 1.6;

  & + p {
    margin-top: 14px;
  }
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    outline: none;
  }
}

a {
  //text-decoration: none;

  color: variables.$signature-color;

  text-decoration-line: underline;
  text-decoration-color: transparent;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  transition: text-decoration-color 0.3s ease-out;

  &.on {
    text-decoration-color: variables.$border-hovered-color;
  }
}

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px variables.$background-color inset;
}

button {
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  font-family: variables.$font-family;
  font-size: 14px;
}

img {
  vertical-align: bottom;
}

.image_wrapper {
  position: relative;
  background-color: rgba(variables.$background-color, 0.5);
  height: 0;
  z-index: 10;

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 20;
  }
}

#root {
  height: 100%;
}

// Scrollbar
// https://wemo.tech/535
::-webkit-scrollbar {
  width: variables.$scrollbar-width;
}

::-webkit-scrollbar-track {
  background: lighten(variables.$background-color, 3%);
  // border-left: 1px solid rgba(variables.$border-hovered-color, .3);
}

::-webkit-scrollbar-thumb {
  background: darken(variables.$background-color, 7.5%);
  // border-left: 1px solid rgba(variables.$border-hovered-color, .3);
}

@media screen and (max-width: variables.$break-point) {
  h1,
  h2 {
    font-size: 24px;
  }

  a {
    transition: none;
  }
}
