@use "../configs/variables";

// Header
header {
  display: flex;
  justify-content: space-between;
  padding: 52px 50px 50px 50px;
  width: calc(100vw - #{variables.$scrollbar-width});

  .logo_wrapper {
    position: relative;
    font-size: 0;
    padding: 0;
    margin: 0;

    &.on {
      //
    }

    .logo {
      display: block;

      img {
        width: 136px;
        height: auto;
      }
    }
  }

  nav {
    ul {
      font-size: 0;
      list-style: none;

      li {
        position: relative;
        display: inline-block;

        &.hide {
          display: none;
        }

        &:not(.hide) + li {
          margin-left: 30px;
        }

        a,
        button {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: variables.$break-point) {
  header {
    align-items: center;
    padding: 25px 20px 22px 20px;
    width: initial;

    .logo_wrapper {
      transition: none;

      .logo {
        img {
          width: 110px;
        }
      }
    }

    nav {
      margin-top: -4px;

      ul {
        li {
          &:not(.hide) + li {
            margin-left: 20px;
          }

          a,
          button {
            font-size: 12px;
          }
        }
      }
    }
  }
}
