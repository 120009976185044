@use "../configs/variables";

// HOME
article.home {
  margin: 120px 50px 50px 50px;

  div {
    & + div {
      margin-top: 40px;
    }

    dl {
      display: flex;

      dt {
        color: rgba(variables.$signature-color, 0.5);
        font-size: 28px;
        line-height: calc(40px * 1.15);
        letter-spacing: -0.025em;
        width: 200px;
      }

      dd {
        width: calc(100% - 200px);

        ul {
          list-style: none;
          font-size: 0;

          li {
            display: flex;

            & + li {
              margin-top: 40px;
            }

            .anchor_wrapper {
              position: relative;
              display: inline-block;

              a {
                display: inline-block;
                color: variables.$signature-color;
                font-size: 42px;
                letter-spacing: -0.025em;
                line-height: 1.15;
                text-decoration: none;

                transition: none;
              }

              // Underline Effect
              .underline {
                position: absolute;
                width: 100%;
                height: 2px;
                left: 0;
                bottom: 0;

                background-color: rgba(variables.$signature-color, 0.6);
                transform: scale(0, 1);
                transform-origin: right top;
                transition: transform 0.3s;
              }

              &.on {
                .underline {
                  transform: scale(1, 1);
                  transform-origin: left top;
                }
              }
            }

            // Icons
            .icons {
              display: flex;
              align-items: center;
              margin: 0 0 0 10px;

              .sound,
              .flash {
                display: block;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 16px auto;

                text-indent: -9999px;
                margin: 6px 0 0 0;
                width: 18px;
                height: 18px;
                overflow: hidden;
                opacity: 0.85;
              }

              .sound {
                background-image: url(/assets/icon_sound.svg);
              }

              .flash {
                background-image: url(/assets/icon_flash.svg);
              }

              .sound + .flash {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: variables.$break-point) {
  article.home {
    margin: 70px 20px 30px 20px;

    div {
      & + div {
        margin-top: 24px;
      }

      dl {
        dt {
          font-size: 18px;
          line-height: calc(28px * 1.15);
          width: 75px;
        }

        dd {
          width: calc(100% - 75px);

          ul {
            li {
              & + li {
                margin-top: 24px;
              }

              .anchor_wrapper {
                a {
                  font-size: 28px;

                  text-decoration-line: underline;
                  text-decoration-thickness: 1px;
                  text-decoration-color: transparent;
                  text-underline-offset: 2px;
                }

                // Underline Effect
                .underline {
                  display: none;
                  transition: none;
                }

                &.on {
                  a {
                    text-decoration-color: rgba(
                      variables.$signature-color,
                      0.6
                    );
                  }
                }
              }

              // Icons
              .icons {
                margin-left: 9px;

                .sound,
                .flash {
                  background-size: 14px auto;
                  width: 16px;
                  height: 16px;
                }

                .sound + .flash {
                  margin-left: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
