@use "../configs/variables";

// Modal
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: -120%;
  z-index: 10000;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.35);
  opacity: 0;
  transition: opacity 0.3s;

  &.default {
    left: 0;
    opacity: 0;
  }

  &.show {
    left: 0;
    opacity: 1;
    touch-action: none;
  }

  .modal_dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .modal_body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 360px;
      height: 200px;

      background-color: white;
      border-radius: 5px;

      .loader_container {
        position: relative;
        width: 50px;
        height: 50px;

        &.hide {
          display: none;
        }

        .loader {
          position: absolute;
          width: 100%;
          height: 100%;

          border-top: 3px solid rgba(variables.$signature-color, 0.05);
          border-right: 3px solid rgba(variables.$signature-color, 0.05);
          border-bottom: 3px solid rgba(variables.$signature-color, 0.05);
          border-left: 3px solid rgba(variables.$signature-color, 0.25);

          border-radius: 50%;
          text-indent: -9999px;
          animation: load8 1.2s infinite linear;
        }

        & + p {
          margin-top: 20px;
          min-height: 22.39px;
        }
      }

      p {
        padding: 0 20px;

        &.error {
          color: variables.$error-color;
        }
      }

      .ok {
        display: none;
        margin-top: 30px;

        // Show on error
        &.show {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: variables.$break-point) {
  .modal {
    .modal_dialog {
      .modal_body {
        width: calc(100% - 60px);
        height: 180px;
      }
    }
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
