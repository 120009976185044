@use "../configs/variables";

// Anchor
.link {
  color: variables.$signature-color;

  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: transparent;
  text-underline-offset: 2px;

  transition: text-decoration-color 0.3s ease-out;

  &.on {
    text-decoration-color: rgba(variables.$signature-color, 0.6);
  }
}

@media screen and (max-width: variables.$break-point) {
  .link {
    transition: none;
  }
}
